'use client';

import { AppWrapper } from '@common';
import { LACHeader } from '@components/common/LACHeader';
import { Toaster } from 'react-hot-toast';

import { ApolloWrapper } from '@client/ApolloWrapper';
import { CartProvider } from '@contexts/cart';
import { CheckoutProvider } from '@contexts/checkout';
import { RecurrenceProvider } from '@contexts/recurrence';
import { HeaderProvider } from '@contexts/headers';
import { TranslateProvider } from '@contexts/TranslateContext';
import { ZipcodeProvider } from '@contexts/zipcode';
import { GTMDataLayerProvider } from '@hooks';
import { Provider as JotaiProvider } from 'jotai';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import { memo } from 'react';

function AppProvider({ children }: React.PropsWithChildren) {
  return (
    <JotaiProvider>
      <HeaderProvider>
        <ApolloWrapper>
          <GTMDataLayerProvider>
            <ZipcodeProvider>
              <CartProvider>
                <RecurrenceProvider>
                  <CheckoutProvider>
                    <TranslateProvider>
                      <AppWrapper>
                        <LACHeader />

                        {children}
                      </AppWrapper>
                      <Toaster containerClassName="mt-10" />
                    </TranslateProvider>
                  </CheckoutProvider>
                </RecurrenceProvider>
              </CartProvider>
            </ZipcodeProvider>
          </GTMDataLayerProvider>
        </ApolloWrapper>
      </HeaderProvider>
      <JotaiDevTools />
    </JotaiProvider>
  );
}

const component = memo(AppProvider);
component.displayName = 'AppProvider';

export default component;
